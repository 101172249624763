/*
 * WARNING: THIS IS AN AUTOGENERATED-FILE. DO NOT MANUALLY EDIT THIS FILE.
 *
 * MODIFY FLAGS DATA IN lib/gating.rb AND RUN `bundle exec bin/generate-ts-constants`
 * TO REGENERATE THIS FILE.
 */

export enum Feature {
  ProdEnvironmentIndicator = "ProdEnvironmentIndicator",
  DeveloperMode = "DeveloperMode",
  ColumnHooks = "ColumnHooks",
  EventHooks = "EventHooks",
  AllowRemoveLogo = "AllowRemoveLogo",
  AdvancedBranding = "AdvancedBranding",
  I18N = "I18N",
  HideImportActivityPreview = "HideImportActivityPreview",
  CustomColumns = "CustomColumns",
  ValidationWebhooks = "ValidationWebhooks",
  Headless = "Headless",
  HeadlessValidation = "HeadlessValidation",
  DynamicTemplates = "DynamicTemplates",
  AuditLogs = "AuditLogs",
  ExtendedMetadataWebhookTimeout = "ExtendedMetadataWebhookTimeout",
  AdvancedMappings = "AdvancedMappings",
  CustomEnvironments = "CustomEnvironments",
  CodeHooksV1 = "CodeHooksV1",
  Customizations = "Customizations",
  Integrations = "Integrations",
  TestMaskDataHighlighting = "TestMaskDataHighlighting",
  InAppBilling = "InAppBilling",
  LegacyEventWebhooks = "LegacyEventWebhooks",
  ValidationWebhooksUnmappedCols = "ValidationWebhooksUnmappedCols",
  TemplateColumnsOrder = "TemplateColumnsOrder",
  LockColumns = "LockColumns",
  SheetOperationEventWebhook = "SheetOperationEventWebhook",
  CsAnalystImport = "CsAnalystImport",
  UserRoleManagement = "UserRoleManagement",
  FileFeeds = "FileFeeds",
  SelfServeTrial = "SelfServeTrial",
  FileFeedPartialImport = "FileFeedPartialImport",
  ServerReachabilityPing = "ServerReachabilityPing",
  DebugTemplatesEndpointLag = "DebugTemplatesEndpointLag",
  EmbeddedPipelineBuilder = "EmbeddedPipelineBuilder",
  FileFeedCustomMetadata = "FileFeedCustomMetadata",
  FileFeedInternalS3 = "FileFeedInternalS3",
  FileFeedEventWebhooks = "FileFeedEventWebhooks",
  DisableDateAutoFormatting = "DisableDateAutoFormatting",
  SuggestedTransforms = "SuggestedTransforms",
  PipelineStepRunPostMappingHooks = "PipelineStepRunPostMappingHooks",
  MalwareFileScan = "MalwareFileScan",
  ShowApiTemplateHooks = "ShowApiTemplateHooks",
  GraphqlApi = "GraphqlApi",
  BulkTargetAttributeUpdate = "BulkTargetAttributeUpdate",
  TemplateJsonHooksField = "TemplateJsonHooksField",
  FileFeedsSavedMappings = "FileFeedsSavedMappings",
  SftpAccountSshKeyNewlineReplacement = "SftpAccountSshKeyNewlineReplacement",
  MultiUseSftpPath = "MultiUseSftpPath",
  FileFeedIngestionHooks = "FileFeedIngestionHooks",
  UploadAllowDatExtension = "UploadAllowDatExtension",
  RecalculateRange = "RecalculateRange",
  RowUniquenessValidation = "RowUniquenessValidation",
  PosthogDisabled = "PosthogDisabled",
  FileFeedExcelSheetSelection = "FileFeedExcelSheetSelection",
  FileFeedVerification = "FileFeedVerification",
  WorkspacesV1 = "WorkspacesV1",
  WorkflowsDemo = "WorkflowsDemo",
}

export enum PermanentFeature {
  WorkspaceCustomerIdentifier = "WorkspaceCustomerIdentifier",
  Importer = "Importer",
  CodeHooksApi = "CodeHooksApi",
  IncreasedCodeHookTimeout = "IncreasedCodeHookTimeout",
}

export const keyCodes = {
  ENTER: 13,
  TAB: 9,
  BACKSPACE: 8,
  ESCAPE: 27,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  DELETE: 46,
  Z: 90,
}

export const emDash = "—"

export const CREATE_NEW_MARKER = "CREATE_NEW"

// Dev AG-Grid-Enterprise Key
export const AG_GRID_ENTERPRISE_KEY =
  /* spell-checker: disable-next-line */
  "Using_this_AG_Grid_Enterprise_key_( AG-045473 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( OneSchema AI Inc. )_is_granted_a_( Single Application )_Developer_License_for_the_application_( OneSchema )_only_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( OneSchema )_need_to_be_licensed___( OneSchema )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 29 September 2024 )____[v2]_MTcyNzU2NDQwMDAwMA==97de9f03d55264105876fbd6eb45bd54"

// Window sizes to be used with media query breakpoints
// Constants from AntD: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
export const screenSize = {
  XS: 480,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1600,
}

export const rowHeight = {
  COMPACT: 29,
  WIDE: 32,
}
